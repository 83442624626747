/* eslint-disable security/detect-object-injection */
import {
    ENGLISH_LANG,
    MAORI_LANG,
    MAORI_LANG_CODE,
    ERROR_OR_NOTIFICATION,
    ADD_TO_KETE,
    COLLECTIONS,
    DOWNLOAD_KETE,
    SEARCH,
    HEADER_FOOTER,
    NAVIGATION,
    HOME_PAGE,
    LANDING_PAGE,
    OCH,
    TWK,
    KWR,
    KOH,
    NZC,
    METADATA,
    SOCIAL_SHARE,
    MY_PROFILE,
    ACTION_BUTTONS,
    COLLECTIONS_SITE_LANGUAGE,
    ADD_TO_KETE_SITE_LANGUAGE,
    DOWNLOAD_KETE_SITE_LANGUAGE,
    SEARCH_SITE_LANGUAGE,
    ENGLISH_OTHER_LANG_CODE,
    CUST_MAORI_LANG_CODE,
    VIDEO_PLAYER,
    DEVELOPMENT_LANGUAGE_TOGGLE,
    DEVELOPMENT_LANGUAGE_PREFERENCE,
    DEVELOPMENT_SITE_NAME
} from './constants';
import { readAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CustomersDataActions.g';
import { ICoreContext } from '@msdyn365-commerce/core';

/*
    This variable is used to set development mode without localstorage errors
    !!!important Please set to false when pushing the code
    Use this only for local development
*/
export const IS_LOCAL_DEVELOPMENT = false;

export const getToggleLang = (context: any) => {
    console.log('gettogglecontext', context);
    if (IS_LOCAL_DEVELOPMENT) {
        return DEVELOPMENT_LANGUAGE_TOGGLE;
    }

    const siteNameFromCache = getSiteNameFromCache(KWR, context);
    if (siteNameFromCache === OCH || siteNameFromCache === KWR) {
        return context.request.locale.toLowerCase() === MAORI_LANG_CODE ? MAORI_LANG : ENGLISH_LANG;
    } else {
        return context.request.locale.toLowerCase() === ENGLISH_OTHER_LANG_CODE ? ENGLISH_LANG : MAORI_LANG;
    }
};

export const getUserPrefLang = (customerAccountNumber: number, context: any): any => {
    console.log('getprefcontext', context);
    if (IS_LOCAL_DEVELOPMENT) {
        return DEVELOPMENT_LANGUAGE_PREFERENCE;
    }

    if (context.request.user.isAuthenticated) {
        if (localStorage !== undefined) {
            return localStorage.getItem(`moeUserPrefLang${customerAccountNumber}`);
        }
    }

    // This piece of code is executed when the user is not logged in
    // We will set toggle language as preferred language
    const siteNameFromCache = getSiteNameFromCache(KWR, context);
    if (siteNameFromCache === OCH || siteNameFromCache === KWR) {
        return context.request.locale.toLowerCase() === MAORI_LANG_CODE ? MAORI_LANG : ENGLISH_LANG;
    } else {
        return context.request.locale.toLowerCase() === ENGLISH_OTHER_LANG_CODE ? ENGLISH_LANG : MAORI_LANG;
    }
};

export const getUserPreferredLanguage = (
    siteName: string,
    moduleName: string,
    userPrefLang: string,
    toggleLang: string,
    context: any
): any => {
    let siteNameFromCache;
    if (IS_LOCAL_DEVELOPMENT) {
        siteNameFromCache = DEVELOPMENT_SITE_NAME;
    } else {
        siteNameFromCache = getSiteNameFromCache(siteName, context);
    }
    // const siteNameFromCache = siteName;

    // For Online Curriculum Hub site
    if (siteNameFromCache === OCH) {
        switch (moduleName) {
            case ERROR_OR_NOTIFICATION:
                return toggleLang;

            case HEADER_FOOTER:
                return toggleLang;

            case HOME_PAGE:
                return toggleLang;

            case ACTION_BUTTONS:
                return toggleLang;

            default:
                return ENGLISH_LANG;
        }
    }

    // For New Zealand Curriculum Online site
    if (siteNameFromCache === NZC) {
        switch (moduleName) {
            case METADATA:
                return ENGLISH_LANG;

            case NAVIGATION:
                return ENGLISH_LANG;

            case HEADER_FOOTER:
                return ENGLISH_LANG;

            case HOME_PAGE:
                return ENGLISH_LANG;

            case SOCIAL_SHARE:
                return ENGLISH_LANG;

            case ERROR_OR_NOTIFICATION:
                return ENGLISH_LANG;

            case MY_PROFILE:
                return ENGLISH_LANG;

            case DOWNLOAD_KETE:
                return ENGLISH_LANG;

            case DOWNLOAD_KETE_SITE_LANGUAGE:
                return ENGLISH_LANG;

            case COLLECTIONS:
                return ENGLISH_LANG;

            case ADD_TO_KETE:
                return ENGLISH_LANG;

            case ADD_TO_KETE_SITE_LANGUAGE:
                return ENGLISH_LANG;

            case SEARCH:
                return ENGLISH_LANG;

            case SEARCH_SITE_LANGUAGE:
                return ENGLISH_LANG;

            case ACTION_BUTTONS:
                return ENGLISH_LANG;

            case COLLECTIONS_SITE_LANGUAGE:
                return ENGLISH_LANG;

            case VIDEO_PLAYER:
                return ENGLISH_LANG;

            default:
                return ENGLISH_LANG;
        }
    }

    // For Te Whāriki Online site
    if (siteNameFromCache === TWK) {
        switch (moduleName) {
            case METADATA:
                return ENGLISH_LANG;

            case NAVIGATION:
                return MAORI_LANG;

            case HEADER_FOOTER:
                return userPrefLang;

            case HOME_PAGE:
                return userPrefLang;

            case LANDING_PAGE:
                return userPrefLang;

            case SOCIAL_SHARE:
                return userPrefLang;

            case ERROR_OR_NOTIFICATION:
                return userPrefLang;

            case MY_PROFILE:
                return userPrefLang;

            case DOWNLOAD_KETE:
                return MAORI_LANG;

            case DOWNLOAD_KETE_SITE_LANGUAGE:
                return userPrefLang;

            case COLLECTIONS:
                return MAORI_LANG;

            case COLLECTIONS_SITE_LANGUAGE:
                return userPrefLang;

            case ADD_TO_KETE:
                return MAORI_LANG;

            case ADD_TO_KETE_SITE_LANGUAGE:
                return userPrefLang;

            case SEARCH:
                return MAORI_LANG;

            case SEARCH_SITE_LANGUAGE:
                return userPrefLang;

            case ACTION_BUTTONS:
                return userPrefLang;

            case VIDEO_PLAYER:
                return userPrefLang;

            default:
                return ENGLISH_LANG;
        }
    }

    // For Te Kōhanga Reo site
    if (siteNameFromCache === KOH) {
        switch (moduleName) {
            case HOME_PAGE:
                return MAORI_LANG;

            case ACTION_BUTTONS:
                return MAORI_LANG;

            case VIDEO_PLAYER:
                return MAORI_LANG;

            default:
                return MAORI_LANG;
        }
    }

    // For Kauwhata Reo site
    if (siteNameFromCache === KWR) {
        switch (moduleName) {
            case METADATA:
                return toggleLang;

            case NAVIGATION:
                return toggleLang;

            case HEADER_FOOTER:
                return userPrefLang === ENGLISH_LANG && toggleLang === ENGLISH_LANG ? ENGLISH_LANG : MAORI_LANG;

            case HOME_PAGE:
                return userPrefLang === ENGLISH_LANG && toggleLang === ENGLISH_LANG ? ENGLISH_LANG : MAORI_LANG;

            case SOCIAL_SHARE:
                return userPrefLang === ENGLISH_LANG && toggleLang === ENGLISH_LANG ? ENGLISH_LANG : MAORI_LANG;

            case ERROR_OR_NOTIFICATION:
                return toggleLang;

            case MY_PROFILE:
                return userPrefLang;

            case DOWNLOAD_KETE:
                return userPrefLang === ENGLISH_LANG && toggleLang === ENGLISH_LANG ? ENGLISH_LANG : MAORI_LANG;

            case DOWNLOAD_KETE_SITE_LANGUAGE:
                return userPrefLang === ENGLISH_LANG && toggleLang === ENGLISH_LANG ? ENGLISH_LANG : MAORI_LANG;

            case COLLECTIONS:
                return userPrefLang === ENGLISH_LANG && toggleLang === ENGLISH_LANG ? ENGLISH_LANG : MAORI_LANG;

            case COLLECTIONS_SITE_LANGUAGE:
                return userPrefLang === ENGLISH_LANG && toggleLang === ENGLISH_LANG ? ENGLISH_LANG : MAORI_LANG;

            case ADD_TO_KETE:
                return userPrefLang === ENGLISH_LANG && toggleLang === ENGLISH_LANG ? ENGLISH_LANG : MAORI_LANG;

            case ADD_TO_KETE_SITE_LANGUAGE:
                return userPrefLang === ENGLISH_LANG && toggleLang === ENGLISH_LANG ? ENGLISH_LANG : MAORI_LANG;

            case SEARCH:
                return MAORI_LANG;

            case SEARCH_SITE_LANGUAGE:
                return userPrefLang === ENGLISH_LANG && toggleLang === ENGLISH_LANG ? ENGLISH_LANG : MAORI_LANG;

            case ACTION_BUTTONS:
                return userPrefLang === ENGLISH_LANG && toggleLang === ENGLISH_LANG ? ENGLISH_LANG : MAORI_LANG;

            case VIDEO_PLAYER:
                return userPrefLang === ENGLISH_LANG && toggleLang === ENGLISH_LANG ? ENGLISH_LANG : MAORI_LANG;

            default:
                return MAORI_LANG;
        }
    }

    switch (moduleName) {
        case DOWNLOAD_KETE:
            return MAORI_LANG;

        case COLLECTIONS:
            return MAORI_LANG;

        case ADD_TO_KETE:
            return MAORI_LANG;

        case SEARCH:
            return MAORI_LANG;

        default:
            return toggleLang;
    }
};

export function setCustomerLangInCache(props: { context: ICoreContext }) {
    const { context } = props;
    const user = context.request.user;

    if (!localStorage.getItem(`moeUserPrefLang${user.customerAccountNumber}`)) {
        void readAsync({ callerContext: context.actionContext, bypassCache: 'get' }, user.customerAccountNumber as any).then(
            customerData => {
                const customerLang = customerData.Language;
                localStorage.setItem(
                    `moeUserPrefLang${user.customerAccountNumber}`,
                    customerLang?.toLowerCase() === CUST_MAORI_LANG_CODE ? MAORI_LANG : ENGLISH_LANG
                );
                window.location.reload();
            }
        );
    }
}

export function setTwksiteLangInCache(props: { context: ICoreContext }, siteName: string) {
    localStorage.setItem(`moeTwkSiteLang`, siteName);
}

export function getTwksiteLangInCache() {
    return localStorage.getItem(`moeTwkSiteLang`);
}

export function setOnlineChannelInfoInCache(context: ICoreContext, channelInfo: string) {
    const hostname = context.request.url?.requestUrl?.hostname;
    if (localStorage !== undefined) {
        localStorage.setItem(`moeOnlineChannelInfo${hostname}`, channelInfo);
        localStorage.setItem('moeOnlineChannelInfoTimer', JSON.stringify(new Date().getTime()));
    }
}

export function getOnlineChannelInfoTimer(context: ICoreContext): number {
    return JSON.parse(localStorage.getItem('moeOnlineChannelInfoTimer') || '0');
}

export function getSiteNameFromCache(siteName: string, context: ICoreContext) {
    const hostname = context.request.url?.requestUrl?.hostname;
    let onlineChannels: any[] = [];

    if (localStorage !== undefined) {
        onlineChannels = JSON.parse(localStorage.getItem(`moeOnlineChannelInfo${hostname}`) || '[]');
    }

    if (onlineChannels?.length > 0) {
        const channelIndex = onlineChannels.findIndex((item: any) => item.Id === context.request.apiSettings.channelId);
        if (channelIndex > -1) {
            if (onlineChannels[channelIndex]?.Name?.includes('ealand')) {
                return NZC;
            }

            if (onlineChannels[channelIndex]?.Name?.includes('hanga')) {
                return KOH;
            }

            if (onlineChannels[channelIndex]?.Name?.includes('hildhood')) {
                return TWK;
            }

            if (onlineChannels[channelIndex]?.Name?.includes('whata')) {
                return KWR;
            }

            if (onlineChannels[channelIndex]?.Name?.includes('Hub')) {
                return OCH;
            }
        }
    } else {
        return siteName;
    }

    return siteName;
}
